<template>
  <div class="hello">
   <div class="page-title">
    公众服务
   </div>
   <div class="page-body">
    <div class="body-content"><span>内容信息</span></div>
    <div class="body-detail">标牌号:   FWN098</div>
    <div class="body-detail">街道:   紫竹院路</div>
    <div class="body-detail">详细位置:   曙光防灾公园旁边</div>
   </div>
   <div class="page-tel">
    <div class="body-detail">客服电话:88462715、88462739</div>
    <div class="tel-contact"><span>联系客服</span></div>
   </div>
   <div class="page-footer">
     <div class="img-box">
       <img src="../assets/logo.png">
     </div>
     <div class="footer-text">
       <p style="cursor: pointer;" @click="jumpUrl">京ICP备20005180号-1  </p>
       <p style="margin-bottom: 10px">Copyright 2018-2021</p>
       <p>北京公共交通控股（集团）有限公司电车供电所</p>
     </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return{

    }
  },
  methods:{
    jumpUrl(){
      window.open('https://beian.miit.gov.cn/')
    },
  },
}
</script>
<style>
  .hello{
    width: 100%;
  }
  .page-title{
    /* padding-top: 20%; */
    font-size: 20px;
    color: #fff;
    width:100%;
    text-align: center;
    height: 100px;
    line-height: 100px;
    background: url('../assets/opbg.png');
    background-size: 100% 100%;
    /* border: 1px solid red; */
    /* background: linear-gradient(90deg,#409eff 0,#2478fc);*/
  }
  .page-body{
    width: 100%;
    /* height: 300px; */
    margin-top: 40px;
    margin-bottom: 115px;
  }
  .page-body div{
    width: 100%;
  }
  .body-content{
    text-align: center;
    margin-bottom: 30px;
  }
  .body-content span{
    color: #fff;
    padding:1px 20px;
    background: linear-gradient(90deg,#409eff 0,#2478fc);
  }
  .body-detail{
    padding-left: 10%;
    margin-bottom: 20px;
  }
  .tel-contact{
    padding-left: 10%;
  }
  .tel-contact span{
    display: inline-block;
    width: 88%;
    background: linear-gradient(90deg,#409eff 0,#2478fc);
    color: #fff;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
.page-footer{
  width: 83%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 105px;
  position: relative;
}
.img-box{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  left: 50px;
}
.img-box img{
  width: 100%;
}
.footer-text p{
  text-align: center;
  padding: 0;
  margin: 0;
}
</style> 
